import {
  FunctionComponent,
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import React from 'react';
import { Label, Loader } from 'semantic-ui-react';
import { apiClient } from 'axios-client';

interface CallSummaryProps {
  conversationUuid?: string;
}

export const CallSummary: FunctionComponent<CallSummaryProps> = ({
  conversationUuid,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [summary, setSummary] = useState<string | null>(null);

  const getSummary = useCallback(
    ({ analyze }: { analyze: boolean }) => {
      setError(false);
      setLoading(true);
      apiClient
        .post<string>(`/form/call/summary`, {
          conversationUuid,
          analyze,
        })
        .then((response) => {
          setSummary(response.data);
        })
        .catch((error) => {
          console.error(error);
          setError(error.message);
        })
        .finally(() => setLoading(false));
    },
    [conversationUuid],
  );

  useEffect(() => {
    getSummary({ analyze: false });
  }, [conversationUuid, getSummary]);

  const handleAnalyze: MouseEventHandler<HTMLAnchorElement> = useCallback(
    async (event) => {
      event.preventDefault();
      getSummary({ analyze: true });
    },
    [getSummary],
  );

  if (error) return <Label color="red">Non disponible</Label>;
  if (loading) return <Loader size="tiny" active inline />;

  return (
    <>
      {summary ?? (
        <a href="#" onClick={handleAnalyze}>
          Analyser
        </a>
      )}
    </>
  );
};
