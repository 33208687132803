import {
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import React from 'react';
import { getToken } from 'utils';
import env from 'env';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const StyledSlider = styled(Slider)`
  width: 125px;
  display: inline-block;
  margin: 2px 12px;
  cursor: pointer;

  .rc-slider-rail {
    height: 8px;
    background-color: #ddd;
    border-radius: 4px;
  }

  .rc-slider-track {
    height: 8px;
    background-color: #3c8dbc;
    border-radius: 4px;
  }

  .rc-slider-handle {
    width: 16px;
    height: 16px;
    border: solid 2px #3c8dbc;
    background-color: #fff;
    border-radius: 50%;
    margin-top: -4px;

    &:hover {
      border-color: #0059c1;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 5px #3c8dbc;
    }

    &:active {
      border-color: #0059c1;
    }
  }

  &.rc-slider-disabled {
    background-color: unset;

    .rc-slider-handle {
      border-color: #ccc;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledButton = styled(Icon)`
  cursor: pointer;
`;

interface CallRecordingProps {
  conversationUuid?: string;
}

const formatTime = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes < 10 ? `0${minutes}` : minutes}:${
    seconds < 10 ? `0${seconds}` : seconds
  }`;
};

export const CallRecording: FunctionComponent<CallRecordingProps> = ({
  conversationUuid,
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const audioUrl = useMemo(() => {
    const baseUrl = `${env.API_URL}/attachment/object-by-key-token`;
    const key = encodeURIComponent(`call-recordings/${conversationUuid}`);
    return `${baseUrl}?key=${key}&token=${getToken()}`;
  }, [conversationUuid]);

  const handleLoadedMetadata = useCallback(() => {
    if (!audioRef.current) return;
    setDuration(audioRef.current.duration);
  }, []);

  const handleTimeUpdate = useCallback(() => {
    const audio = audioRef.current;
    if (!audio) return;
    if (audio.currentTime === duration) {
      audio?.pause();
      setIsPlaying(false);
    }
    setCurrentTime(audio.currentTime);
  }, [duration]);

  const handleSliderChange = useCallback((value: number | number[]) => {
    if (!audioRef.current) return;
    audioRef.current.currentTime = Array.isArray(value) ? value?.[0] : value;
  }, []);

  const handlePlayPause = useCallback(() => {
    if (isPlaying) {
      audioRef.current?.pause();
    } else {
      audioRef.current?.play();
    }
    setIsPlaying(!isPlaying);
  }, [isPlaying]);

  const handleReset = useCallback(() => {
    if (!audioRef.current) return;
    audioRef.current.currentTime = 0;
    setCurrentTime(0);
    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  }, [isPlaying]);

  return (
    <Wrapper>
      <audio
        ref={audioRef}
        src={audioUrl}
        onLoadedMetadata={handleLoadedMetadata}
        onTimeUpdate={handleTimeUpdate}
      />
      <StyledButton
        onClick={handlePlayPause}
        name={isPlaying ? 'pause' : 'play'}
        disabled={duration === 0}
      />
      &nbsp;{formatTime(currentTime)}&nbsp;/&nbsp;{formatTime(duration)}&nbsp;
      <StyledSlider
        max={duration}
        min={0.5}
        value={currentTime}
        onChange={handleSliderChange}
        disabled={duration === 0}
        step={0.5}
      />
      <StyledButton
        onClick={handleReset}
        name="undo"
        disabled={duration === 0}
      />
    </Wrapper>
  );
};
