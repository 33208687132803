import React, { FunctionComponent } from 'react';

import { BilikActor, MongoEvent } from 'interfaces/events.interface';
import TimelineEvent from '../timeline-event';
import { pathOr } from 'remeda';
import TimelineSmsPreview from '../timeline-sms-preview';
import { getBilikUserName } from 'utils/get-bilik-username';

type SmsSentProps = {
  event: MongoEvent;
};

/**
 * v0 : Callr in a db
 * v1: Callr without db (flat data)
 * v2: Twilio
 * v3: Vonage
 */

const SmsSent: FunctionComponent<SmsSentProps> = ({ event }) => {
  const bilik = event.initiator.bilik;

  const getMessageText = (event: MongoEvent): string => {
    switch (event.version) {
      case 0:
        return event.data.callrMessage.text;
      case 1:
        return event.data.callrMessage.text;
      case 2:
        return event.data.twilio.Body;
      case 3:
        return event.data.vonage.text;
      default:
        return event.data.vonage.text;
    }
  };

  const getBilikMainCity = (bilik: BilikActor): string => {
    switch (event.version) {
      case 1:
        return bilik.bilikZone.mainCity as any; // Any because In v1, mainCity was a string
      case 0 | 2 | 3:
        return bilik.bilikZone.mainCityCode;
      default:
        return bilik.bilikZone.mainCityCode;
    }
  };

  return (
    <TimelineEvent
      date={event.date}
      icon="mobile alternate"
      title="SMS au particulier"
      initiator={bilik.shortCode ? 'bilik-bot' : 'bilik-user'}
      bilikUserName={bilik.givenName ? getBilikUserName(bilik) : undefined}
    >
      {/* Sms from bilik to Pi */}
      {bilik && event.actors.pi ? (
        <>
          <>
            <h3>
              {bilik.shortCode ? (
                <>
                  <span>
                    <b>&quot;{bilik.shortCode}&quot;</b> a envoyé un SMS au
                    particulier
                  </span>
                </>
              ) : (
                <>
                  <span>Bilik {getBilikMainCity(bilik)}</span> a envoyé un SMS
                  au particulier
                </>
              )}
            </h3>
            <section>
              <p>
                <b>Message: </b>
                {getMessageText(event)}
              </p>
            </section>
          </>
          <section>
            {event.version && event.version > 2 ? (
              <TimelineSmsPreview
                title={'Suivi sms : '}
                messageId={event.data.messageId}
              />
            ) : null}
          </section>
        </>
      ) : null}
    </TimelineEvent>
  );
};
export default SmsSent;
