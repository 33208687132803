import {
  Container,
  Form,
  Grid,
  Icon,
  Pagination,
  FormField as FormFieldUi,
  Loader,
} from 'semantic-ui-react';
import {
  BooleanParam,
  DateParam,
  NumberParam,
  ObjectParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { CallsTable } from '../../components/calls-table';
import Content from 'components/content/content';
import ContentHeader from 'components/content-header/content-header';
import ContentHeaderTitle from 'components/content-header-title/content-header-title';
import ContentBody from 'components/content-body/content-body';
import { useCurrentBilikZone } from 'hooks/use-current-bilik-zone/use-current-bilik-zone';
import { startOfMonth, subMonths } from 'date-fns';
import DatePicker from 'react-datepicker';
import { fr } from 'date-fns/locale';
import { Checkbox } from 'semantic-ui-react';
import { useCallsCount } from 'hooks/use-calls-count/use-calls-count';
import Label from 'components/label/label';

export const CallsListPage: FunctionComponent = () => {
  const { currentBilikZone } = useCurrentBilikZone();

  const itemPerPage = useMemo(() => 30, []);

  const [filters, setFilters] = useQueryParams({
    orderBy: ObjectParam,
    page: withDefault(NumberParam, 0),
    startDate: withDefault(DateParam, startOfMonth(subMonths(new Date(), 5))),
    endDate: withDefault(DateParam, new Date()),
    toManager: withDefault(BooleanParam, false),
  });

  const where = useMemo(() => {
    return {
      after: filters.startDate,
      before: filters.endDate,
      filters: currentBilikZone?.id
        ? [
            {
              $or: [
                {
                  'initiator.pro.bilikZone.id': `/bilik_zones/${currentBilikZone.id}`,
                },
                {
                  'actors.pro.bilikZone.id': `/bilik_zones/${currentBilikZone.id}`,
                },
                {
                  'actors.bilik.bilikZone.id': currentBilikZone.id,
                },
              ],
            },
            filters?.toManager
              ? {
                  'actors.bilik.bilikZone.id': currentBilikZone.id,
                }
              : {},
          ]
        : undefined,
    };
  }, [filters.toManager, filters.startDate, filters.endDate, currentBilikZone]);

  const { count, loading: countLoading } = useCallsCount(
    where,
    currentBilikZone === undefined,
  );

  const totalPages = useMemo(
    () => Math.ceil((count ? count : 0) / itemPerPage),
    [count],
  );

  useEffect(() => {
    setFilters({ page: 1 });
  }, [currentBilikZone]);

  const onStartDateChanged = useCallback(
    (date): void => {
      // Interchange date if newDate > endDate
      if (date > filters.endDate) {
        setFilters({
          startDate: filters.endDate,
        });
        setFilters({ endDate: date });
      } else {
        setFilters({ startDate: date });
      }
    },
    [setFilters, filters.endDate],
  );

  const onEndDateChanged = useCallback(
    (date): void => {
      setFilters({ page: 1 });
      // Interchange date if newDate < startDate
      if (date < filters.startDate) {
        setFilters({
          endDate: filters.startDate,
        });
        setFilters({ startDate: date });
      } else {
        setFilters({ endDate: date });
      }
    },
    [setFilters, filters.startDate],
  );

  if (currentBilikZone === undefined) {
    return (
      <Content>
        <Loader
          style={{ marginTop: '50px' }}
          size="large"
          active
          inline="centered"
        >
          Chargement...
        </Loader>
      </Content>
    );
  }

  return (
    <Content>
      <ContentHeader>
        <Grid columns={2}>
          <Grid.Column>
            <ContentHeaderTitle>
              Appels{' '}
              <small>
                {currentBilikZone
                  ? currentBilikZone.mainCityCode
                  : 'Toutes les zones'}
              </small>
            </ContentHeaderTitle>
          </Grid.Column>
        </Grid>
      </ContentHeader>
      <ContentBody>
        <Form>
          <Form.Group>
            <FormFieldUi>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                locale={fr}
                selected={filters.startDate}
                onChange={onStartDateChanged}
              />
            </FormFieldUi>
            <FormFieldUi>
              <DatePicker
                style={{ maxWidth: '150px' }}
                dateFormat="dd/MM/yyyy"
                locale={fr}
                selected={filters.endDate}
                onChange={onEndDateChanged}
              />
            </FormFieldUi>
            {currentBilikZone ? (
              <Checkbox
                style={{ marginTop: '10px', marginLeft: '5px' }}
                checked={filters.toManager}
                label="Appels vers la zone"
                onChange={(): void => {
                  setFilters({
                    toManager: !filters.toManager,
                    page: 1,
                  });
                }}
              />
            ) : null}
          </Form.Group>
        </Form>

        <CallsTable
          filters={where}
          onFilter={(column, value): void => {
            setFilters({
              [column]: value,
            });
          }}
          limit={itemPerPage}
          skip={filters?.page ? itemPerPage * (filters.page - 1) : 0}
          orderBy={{
            column: filters.orderBy?.column ?? 'dateCreated',
            direction: filters.orderBy?.direction ?? 'descending',
          }}
          onSort={(column, direction): void => {
            setFilters({
              orderBy: { column, direction },
            });
          }}
        />
        <Container fluid textAlign="right" style={{ marginTop: '15px' }}>
          <Pagination
            disabled={countLoading}
            activePage={filters.page ?? 1}
            totalPages={totalPages}
            ellipsisItem={null}
            firstItem={{
              content: <Icon name="angle double left" />,
              icon: true,
            }}
            lastItem={{
              content: <Icon name="angle double right" />,
              icon: true,
            }}
            prevItem={{ content: <Icon name="angle left" />, icon: true }}
            nextItem={{ content: <Icon name="angle right" />, icon: true }}
            onPageChange={(_, data): void => {
              setFilters({ page: Number(data.activePage) });
            }}
          />
        </Container>
      </ContentBody>
    </Content>
  );
};
